import React, { useCallback } from 'react';
import { useSiedeBar } from '../../hooks/sidebar';
import { useAuth } from '../../hooks/auth';
import { useTheme } from '../../hooks/theme';
import brandImg from '../../assets/brand.png';
import * as S from './styles';

const Header: React.FC = () => {
  const { signOut, user } = useAuth();
  const { onToogleOpenSideBar } = useSiedeBar();
  const { theme, onChangeTheme } = useTheme();

  const avatarImg = user.avatar_url
    ? user.avatar_url
    : 'https://api.adorable.io/avatars/96/abott@adorable.png';

  const handleToogleMenu = useCallback(() => {
    onToogleOpenSideBar();
  }, [onToogleOpenSideBar]);

  const handleSetTheme = useCallback(
    (themeSelected: string) => {
      onChangeTheme(themeSelected);
    },
    [onChangeTheme],
  );

  return (
    <S.Container>
      <S.WrapperLeft>
        <S.IconMenu onClick={handleToogleMenu} size={24} />
        <S.Brand height="40" src={brandImg} />
      </S.WrapperLeft>

      <S.WrapperRight>
        {theme === 'dark' && (
          <S.IconDarkTheme size={24} onClick={() => handleSetTheme('light')} />
        )}
        {theme === 'light' && (
          <S.IconLightTheme size={24} onClick={() => handleSetTheme('dark')} />
        )}
        <S.IconNotifications size={24} />

        {/* <IconContainer>
          <MdSettings size={24} />
        </IconContainer> */}
        <S.AvatarInfo>
          <S.Avatar src={avatarImg} />
          <S.UserInfo>
            <S.UserName>{user.name}</S.UserName>
            {/* <RoleName>{user.users_roles[0]}</RoleName> */}
          </S.UserInfo>
        </S.AvatarInfo>
        <S.IconSettings onClick={signOut} size={24} />
      </S.WrapperRight>
    </S.Container>
  );
};

export default Header;
