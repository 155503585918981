import React from 'react';
import { IconBaseProps } from 'react-icons';

import * as S from './styles';

interface HeaderInfoProps {
  title: string | undefined;
  icon?: React.ComponentType<IconBaseProps>;
}

const HeaderInfo: React.FC<HeaderInfoProps> = ({ title, icon: Icon }) => {
  return (
    <S.Container>
      {Icon && <Icon size={32} />}
      <S.Title>{title}</S.Title>
    </S.Container>
  );
};

export default HeaderInfo;
