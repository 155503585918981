/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  no-param-reassign */
/* eslint-disable  no-underscore-dangle */
/* eslint-disable   @typescript-eslint/no-unused-vars */
/* eslint-disable   react/require-default-props */
/* eslint-disable   @typescript-eslint/explicit-module-boundary-types */

import React, { useState, useMemo, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Container, InputFile } from './styles';

export type CropProps = {
  unit?: 'px' | '%';
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  aspect?: number;
};

export type FilesProps = {
  image_original_file?: File | string;
  image_crop_file?: File | Blob | string;
  name?: string;
  crop: CropProps;
  isfileChanged: boolean;
};

export type InputImageCropProps = {
  name: string;
  source?: string;
  sourceCrop?: string;
  initCrop?: CropProps;
  fileNameOriginal?: string;
  label: string;
  onChange(
    image_original_file: File,
    image_crop_file: File | Blob,
    name: string,
    crop: CropProps,
  ): void;
};

export default function InputImageCrop({
  name,
  source = '',
  sourceCrop = '',
  fileNameOriginal = '',
  initCrop = {
    aspect: 16 / 9,
    height: 309,
    unit: 'px',
    width: 500,
    x: 79.5,
    y: 38.171875,
  },
  label,
  onChange,
}: InputImageCropProps) {
  const [nameOriginal, setNameOriginal] = useState(fileNameOriginal);
  const [imageRef, setImageRef] = useState();
  const [fileUrl, setFileUrl] = useState('');
  const [fileSelected, setFileSelected] = useState<File>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileCropSelected, setFileCropSelected] = useState();
  const [src, setSrc] = useState(source);
  const [imagePreview, setImagePreview] = useState(sourceCrop);
  const [crop, setCrop] = useState(initCrop);

  async function getBase64ImageFromUrl(imageUrl: any) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false,
      );

      // reader.onerror = () => {
      //   return reject(this);
      // };

      reader.readAsDataURL(blob);
    });
  }
  async function getBlobImageFromUrl(imageUrl: any) {
    const blob = (await fetch(imageUrl).then((r) => r.blob())) as File;
    return blob;
  }
  async function loadBlobImages() {
    const file = await getBlobImageFromUrl(source);
    const file_crop = await getBlobImageFromUrl(sourceCrop);

    setFileSelected(file as File);
    setFileCropSelected(file_crop as any);

    // Não seta por que pode ser que o user está atualizando e não
    // mexeu na imagem.
    // onChange(file, file_crop, crop);
    setCrop(initCrop);
  }

  useMemo(() => {
    getBase64ImageFromUrl(source).then((result) => {
      setSrc(result as any);
    });
    setImagePreview(sourceCrop);
    loadBlobImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, sourceCrop]);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setFileSelected(e.target.files[0]);
      setNameOriginal(e.target.files[0].name);
      // console.log('FILE SELECIONADO INPUT:', e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => setSrc(reader.result as any));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  // If you setState the crop in here you should return false.
  function onImageLoaded(image: any) {
    setImageRef(image);
    // console.log('onImageLoaded', image);
  }

  function onCropChange(cropSelected: any) {
    // You could also use percentCrop:
    // console.log('CROPSELECTED', cropSelected);
    setCrop(cropSelected);
  }

  function getCroppedImg(
    image: any,
    cropSelected: any,
    fileName?: any,
  ): Promise<any> {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropSelected.width;
    canvas.height = cropSelected.height;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      cropSelected.x * scaleX,
      cropSelected.y * scaleY,
      cropSelected.width * scaleX,
      cropSelected.height * scaleY,
      0,
      0,
      cropSelected.width,
      cropSelected.height,
    );

    // console.log('Canvas', canvas);
    // const base64Image = canvas.toDataURL('image/jpeg');
    // console.log('Base64', base64Image);

    return new Promise((resolve, reject) => {
      // const base64Image = canvas.toDataURL('image/jpeg');
      // setFileUrl(FILE_URL as any);
      // setFileCropSelected(base64Image as any);
      // onChange(
      //   fileSelected,
      //   base64Image,
      //   cropSelected,
      //   fileSelected?.name || 'fileName',
      //   fileSelected?.type || 'jpeg',
      // );
      // resolve(base64Image);

      canvas.toBlob((blob: any) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          // console.error('Canvas is empty');
          return;
        }
        blob.name = nameOriginal;
        // console.log('blob', blob);
        window.URL.revokeObjectURL(fileUrl);
        const FILE_URL = window.URL.createObjectURL(blob);
        setFileUrl(FILE_URL);
        setFileCropSelected(blob);
        onChange(fileSelected as File, blob, nameOriginal, cropSelected);
        resolve(FILE_URL);
      }, fileSelected?.type || 'jpeg');
    });
  }

  async function makeClientCrop(cropSelected: CropProps) {
    if (imageRef && cropSelected.width && cropSelected.height) {
      // console.log('fileSelected MakeClientCrop', fileSelected);

      const imagePreviewGenerated = await getCroppedImg(
        imageRef,
        cropSelected,
        'image-cropped.jpeg',
      );

      // console.log('imagePreviewGenerated', imagePreviewGenerated);

      // onChange();
      setImagePreview(imagePreviewGenerated);
    }
  }

  function onCropComplete(cropSelected: CropProps) {
    // console.log('cropSelected complet', cropSelected);
    makeClientCrop(cropSelected);
  }

  // useEffect(() => {
  //   return () => {
  //     URL.revokeObjectURL(fileUrl as any);
  //     URL.revokeObjectURL(fileSelected as any);
  //     URL.revokeObjectURL(src as any);
  //     URL.revokeObjectURL(imagePreview as any);
  //   };
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    setNameOriginal(fileNameOriginal);
  }, [fileNameOriginal]);

  return (
    <>
      <Container>
        <InputFile
          type="file"
          accept=".jpeg, .jpg"
          id={`file_${name}`}
          onChange={(e) => {
            onSelectFile(e);
          }}
        />
        <label htmlFor={`file_${name}`}>{label}</label>
        <br />
        <>
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
            />
          )}
          {imagePreview && (
            <>
              <span>Preview capa</span>
              <img
                alt="Crop"
                src={imagePreview}
                // styles={{ width: crop.width, height: crop.height }}
              />
            </>
          )}
        </>
      </Container>
    </>
  );
}
