/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  no-param-reassign */
/* eslint-disable  no-underscore-dangle */
/* eslint-disable   @typescript-eslint/no-unused-vars */
/* eslint-disable  no-undef */
import React from 'react';
import { FieldError, Controller } from 'react-hook-form';
import { FiAlertCircle } from 'react-icons/fi';
import CKEditor from '@ckeditor/ckeditor5-react';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import { Container, ErrorContainer, ErrorMsg } from './styles';

class MyUploadAdapter {
  private xhr: any;

  private REACT_APP_URL_UPLOAD = process.env.REACT_APP_URL_UPLOAD as string;

  constructor(private loader: any) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file: any) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        }),
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest()); // eslint-disable-line no-multi-assign
    const token = localStorage.getItem('@plox:token');
    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    // xhr.open('POST', 'http://localhost:3333/uploads', true);
    xhr.open('POST', this.REACT_APP_URL_UPLOAD, true);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve: any, reject: any, file: any) {
    const { xhr } = this;
    const { loader } = this;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    // eslint-disable-next-line consistent-return
    xhr.addEventListener('load', () => {
      const { response } = xhr;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error
            ? response.error.message
            : genericErrorText,
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url,
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt: any) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file: any) {
    // Prepare the form data.
    const data = new FormData();

    data.append('image_body', file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    // Configure the URL to the upload script in your back-end here!
    return new MyUploadAdapter(loader);
  };
}
const config2 = {
  extraPlugins: [MyCustomUploadAdapterPlugin],
  language: 'pt-BR',
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Parágrafo',
        class: 'ck-heading_paragraph',
      },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Título H1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Título H2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Título H3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Título H4',
        class: 'ck-heading_heading4',
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Título H5',
        class: 'ck-heading_heading5',
      },
    ],
  },
};

const config = {
  extraPlugins: [MyCustomUploadAdapterPlugin],
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
      '|',
      'imageUpload',
      'blockQuote',
      'insertTable',
      'mediaEmbed',
      'undo',
      'redo',
      'htmlEmbed',
    ],
  },
  language: 'pt-br',
  image: {
    toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },
};

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;
interface InputEditorProps {
  name: string;
  register: RefReturn;
  control: any;
  errors: FieldError | undefined;
}

const InputEditor: React.FC<InputEditorProps> = ({
  name,
  register,
  control,
  errors,
}) => {
  return (
    <Container>
      {errors && (
        <ErrorContainer>
          <FiAlertCircle color="#c53030" size={20} />
          <ErrorMsg>{errors?.message}</ErrorMsg>
        </ErrorContainer>
      )}
      <Controller
        name={name}
        control={control}
        ref={register}
        defaultValue=""
        render={({ onChange, value }) => (
          <CKEditor
            editor={ClassicEditor}
            config={config}
            data={value}
            onReady={(editor: any) => {
              // // You can store the "editor" and use when it is needed.
              // console.log('Editor is ready to use!', editor);
            }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              onChange(data);
            }}
            onBlur={(event: any, editor: any) => {}}
            onFocus={(event: any, editor: any) => {}}
          />
        )}
      />
    </Container>
  );
};

export default InputEditor;
