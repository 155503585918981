/* eslint-disable no-undef */
import axios from 'axios';

declare let window: any;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.localStorage.removeItem('@plox:token');
      window.localStorage.removeItem('@plox:user');
      window.location = `${process.env.REACT_APP_URL_FRONT}/signin`;
    }
    // if (
    //   error.response.status === 401 &&
    //   (error.response.statusText === 'Unauthorized' ||
    //     error.response.statusText === 'Invalid JWT token')
    // ) {
    //   window.localStorage.removeItem('@plox:token');
    //   window.localStorage.removeItem('@plox:user');
    //   window.location = `${process.env.REACT_APP_URL_FRONT}/signin`;
    // }
    return Promise.reject(error);
  },
);

export default api;
