import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';

import Dashboard from '../pages/Dashboard';
// import Profile from '../pages/Profile';

import Post from '../pages/Post';
import PostForm from '../pages/Post/PostForm';
import Municipio from '../pages/Muncipio';
import MunicipioForm from '../pages/Muncipio/MunicipioForm';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Redirect path="/" to="/signin" exact />
      <Route path="/signin" component={SignIn} />
      <Route path="/dashboard" component={Dashboard} isPrivate exact />
      <Route path="/posts" component={Post} isPrivate exact />
      <Route path="/posts/new" component={PostForm} isPrivate exact />
      <Route path="/posts/:id" component={PostForm} isPrivate exact />
      <Route path="/municipios" component={Municipio} isPrivate exact />
      <Route
        path="/municipios/:codigo"
        component={MunicipioForm}
        isPrivate
        exact
      />
    </Switch>
  );
};

export default Routes;
