import styled, { css } from 'styled-components';
import { MdEdit, MdDelete, MdFileUpload } from 'react-icons/md';

export const Container = styled.div`
  margin-top: 48px;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
`;

export const HeaderPage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 0;

  @media (max-width: 768px) {
    padding-top: 4px;
    flex-direction: column;
  }

  > div {
    @media (max-width: 768px) {
      padding-top: 4px;
    }
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 16px;

  & > div {
    max-width: 400px;
    transition: max-width 0.2s;
  }
  & > div:hover {
    max-width: 500px;
  }
`;

export const Panel = styled.div`
  /* background: var(--background-panel); */
  /* padding: 16px; */
  margin-top: 32px !important;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
  width: fit-content;
  margin: 0px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterContainer = styled.div`
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background);
  padding: 0px 16px;
`;

const IconCss = css`
  flex-shrink: 0;
  padding: 4px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    background: #ffffff12;
  }
`;

export const IconEdit = styled(MdEdit)`
  ${IconCss}
`;
export const IconUpload = styled(MdFileUpload)`
  ${IconCss}
`;
export const IconDelete = styled(MdDelete)`
  ${IconCss}
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: yellow;
`;
