import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 48px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

export const HeaderPage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 0;

  @media (max-width: 768px) {
    padding-top: 4px;
    flex-direction: column;
  }

  > div {
    @media (max-width: 768px) {
      padding-top: 4px;
    }
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 16px;

  & > div {
    max-width: 400px;
    transition: max-width 0.2s;
  }
  & > div:hover {
    max-width: 500px;
  }
`;

export const Panel = styled.div`
  margin-top: 32px !important;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
  width: fit-content;
  margin: 0px auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
`;

export const ContainerMuncipio = styled.div`
  height: 132px;
  width: 410px;
  /* margin-bottom: 16px; */
  background: var(--background-panel);
  border-radius: 8px;
  padding: 16px;
`;

export const MunicipioInfo = styled.div``;
export const MunicipioHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Ibge = styled.p``;
export const Name = styled.h2`
  margin-bottom: 8px;
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--text);
  transition: color 0.6s;
  letter-spacing: -1.25px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 728px) {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.8rem;
    letter-spacing: -2px;
    text-align: left;
  }
`;
export const StartAt = styled.p``;
export const Slug = styled.p``;
export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border: none;
    background-color: transparent;
  }
`;
