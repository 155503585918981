import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

export const Panel = styled.div`
  background: var(--background-panel);
  border-radius: 8px;
  margin: 0px auto;
  height: auto;
  width: 100%;
  max-width: 1024px;
  padding: 24px;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
  & > form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1024px;
  }
`;

export const HeaderPageDatail = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  margin-bottom: 24px;

  & div + div {
    //margin: 8px 32px 0px 32px;
    justify-content: flex-start;
  }
`;
export const BackLink = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //margin: 0px 32px 0px 32px;
  margin-bottom: 8px;

  svg {
    margin-right: 16px;
  }
  a {
    text-decoration: none;
    color: var(--text);
  }
`;
