import styled from 'styled-components';

interface ContainerProps {
  color: string;
}

export const Container = styled.div<ContainerProps>`
  height: 208px;
  width: 900px;
  margin-bottom: 16px;
  background: var(--background-panel);
  border-radius: 8px;

  border-right: 8px solid ${({ color }) => color};

  @media (max-width: 576px) {
    width: 100%;
    height: auto;
  }
`;

export const Post = styled.article`
  width: 100%;
  height: 100%;
`;

export const PostContainer = styled.div`
  display: flex;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const ContainerImage = styled.div`
  height: 208px;
  max-width: 370px;
  width: 100%;
  position: relative;
`;

export const Image = styled.img`
  height: 208px;
  max-width: 370px;
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  @media (max-width: 576px) {
    max-width: 100%;
    height: 210px;
  }
`;

interface ChannelProps {
  bgColor: string;
  lbColor: string;
}

export const Channel = styled.span<ChannelProps>`
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 1.4rem;
  width: fit-content;
  font-weight: 600;
  color: #fff;
  border-radius: 1px;
  padding: 2px 8px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ lbColor }) => lbColor};
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);

  @media (max-width: 576px) {
    position: absolute;
    top: -16px;
  }
`;

export const Content = styled.div`
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);

  /* border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; */

  width: 100%;
  padding: 8px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 576px) {
    padding: 0px 16px;
  }
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Legend = styled.div``;
export const Position = styled.strong`
  color: var(--text);
`;

interface hatProps {
  bgColor: string;
  lbColor: string;
}

export const Hat = styled.span<hatProps>`
  font-size: 1.4rem;
  width: fit-content;
  font-weight: 600;
  color: #fff;
  border-radius: 1px;
  padding: 2px 8px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ lbColor }) => lbColor};
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);

  @media (max-width: 576px) {
    position: absolute;
    top: -16px;
  }
`;

export const Title = styled.h2`
  margin-top: 8px;
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--text);
  transition: color 0.6s;
  letter-spacing: -1.25px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 728px) {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.8rem;
    letter-spacing: -2px;
    text-align: left;
  }
`;

export const SubTitle = styled.p`
  margin-top: 4px;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: var(--text);

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const PostInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
`;

export const PostFooter = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;
export const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 8px;
`;
export const AuthorName = styled.strong`
  display: block;
  color: var(--text);
  font-size: 1.4rem;
  margin-bottom: 4px;
`;
export const CreatedAt = styled.span`
  display: block;
  color: var(--text);
  font-size: 1rem;
  margin-bottom: 2px;
`;
export const LastUpdatedAt = styled.span`
  display: block;
  color: var(--text);
  font-size: 1rem;
`;
export const Actions = styled.div`
  /* span + span,
  span + a,
  a + span,
  a + a {
    margin-left: 8px;
  } */

  button {
    border: none;
    background-color: transparent;
  }
`;
