export default [
  {
    id: 1,
    name: 'acontece',
    color: '#833471',
  },
  {
    id: 13,
    name: 'atitude',
    color: '#e58e26',
  },
  {
    id: 15,
    name: 'bichos',
    color: '#218c74',
  },

  {
    id: 8,
    name: 'economia',
    color: '#303e30',
  },
  {
    id: 6,
    name: 'educação',
    color: '#614b4b',
  },
  {
    id: 9,
    name: 'emprego',
    color: '#636e72',
  },
  {
    id: 7,
    name: 'esportes',
    color: '#009432',
  },
  {
    id: 17,
    name: 'estilo',
    color: '#fab1a0',
  },
  {
    id: 4,
    name: 'famosos',
    color: '#FC427B',
  },
  {
    id: 20,
    name: 'inusitado',
    color: '#ab8ebc',
  },
  {
    id: 10,
    name: 'lazer',
    color: '#cd6133',
  },
  {
    id: 18,
    name: 'mundo',
    color: '#e77f67',
  },
  {
    id: 2,
    name: 'polícia',
    color: '#ea2027',
  },
  {
    id: 3,
    name: 'política',
    color: '#0a3d62',
  },
  {
    id: 12,
    name: 'saúde',
    color: '#119482',
  },
  {
    id: 5,
    name: 'tecnologia',
    color: '#f9681c',
  },
  {
    id: 11,
    name: 'turismo',
    color: '#c4e538',
  },
  {
    id: 19,
    name: 'veículos',
    color: '#706fd3',
  },
];
