import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiMail, FiLock } from 'react-icons/fi';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { Input, Button } from '../../components';

import logoImg from '../../assets/plox_default.png';
import { Container, Content, Background, AnimationContainer } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}
const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
  password: Yup.string().required('Senha obrigatória'),
});

const SignIn: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<SignInFormData>({
    resolver: yupResolver(schema),
  });
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const onSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro na autenricação',
          description:
            'Ocorreu um erro ao fazer login, verifique as credenciais EMAIL / SENHA',
        });
      }
    },
    [signIn, addToast],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img width="200" height="108" src={logoImg} alt="Plox" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Faça seu logon</h1>

            <Input
              icon={FiMail}
              title="E-mail"
              name="email"
              type="text"
              placeholder="informe seu email"
              register={register}
              errors={errors.email}
            />

            <Input
              icon={FiLock}
              title="Senha"
              name="password"
              type="password"
              placeholder="Senha"
              autoComplete="off"
              register={register}
              errors={errors.password}
            />

            {/*  <Input name="email" icon={FiMail} placeholder="E-mail" />
            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
              autoComplete="off"
            /> */}

            <Button variant="primary" type="submit">
              Entrar
            </Button>
          </form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
