import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  margin-top: 48px;
`;
export const Main = styled.div`
  display: flex;
  position: relative;
`;
