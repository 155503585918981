import styled from 'styled-components';

interface ContainerProps {
  isMenuOpened: boolean;
}

export const Container = styled.div<ContainerProps>`
  overflow: auto;
  margin-left: ${(props) => (props.isMenuOpened ? '240px' : '54px')};
  width: 100%;
  height: 100%;
`;
