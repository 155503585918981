import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MdSearch, MdAssignment } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import {
  InputSearch,
  HeaderInfo,
  Pagination,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Position,
  Button,
  Loader,
} from '../../components';
import Card from './Card';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { ModalHandles } from '../../components/Modal';
import * as S from './styles';
import IPost from './interfaces/IPost';

interface IPaginate {
  data: IPost[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const Post: React.FC = () => {
  const history = useHistory();
  const [posts, setPosts] = useState<IPaginate>();
  const [post, setPost] = useState<IPost>();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const { addToast } = useToast();
  const modalRemoverRef = useRef<ModalHandles>(null);

  const handleSearch = useCallback(
    async ({ page, per_page = 10 }) => {
      try {
        setLoading(true);
        const params = {
          page,
          per_page,
          term,
        };
        const response = await api.get<IPaginate>('/posts', { params });
        const { data } = response;
        setPosts(data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo de pesquisa',
        });
      } finally {
        setLoading(false);
      }
    },
    [term, addToast],
  );

  const handleRemove = async (): Promise<void> => {
    try {
      await api.delete(`/posts/${post?.id}`);
      if (!posts) return;
      const newList = posts.data.filter((item) => item.id !== post?.id);
      setPosts({ ...posts, data: newList });
      modalRemoverRef.current?.closeModal();
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Produtos removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao inserir um novo registro',
      });
    }
  };

  const handleOpenModalRemover = useCallback((postSelected: IPost): void => {
    setPost(postSelected);
    modalRemoverRef.current?.openModal();
  }, []);

  const handleAdd = useCallback((): void => {
    history.push(`/posts/new`);
  }, [history]);

  // const onSearch = useCallback(
  //   (event: React.KeyboardEvent<HTMLInputElement>) => {
  //     if (event.key === 'Enter') {
  //       handleSearch({ page: 1, per_page: posts?.per_page });
  //     }
  //   },
  //   // eslint-disable-next-line
  //   [],
  // );
  const onSearch = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSearch({ page: 1, per_page: posts?.per_page });
    }
  };

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 50,
    };

    handleSearch(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <S.HeaderPage>
        <HeaderInfo title="Notícias" icon={MdAssignment} />

        <S.ContainerSearch>
          <InputSearch
            title="Pesquisar pelo título"
            icon={MdSearch}
            placeholder="Pesquisar por título"
            onChange={(e) => setTerm(e.target.value)}
            onKeyDown={onSearch}
          />
        </S.ContainerSearch>

        <div>
          <Button title="Adicionar" onClick={handleAdd}>
            Adicionar
          </Button>
        </div>
      </S.HeaderPage>

      <S.FooterContainer>
        {posts && (
          <Pagination
            page={posts?.page}
            total={posts?.total}
            per_page={posts?.per_page}
            last_page={posts?.last_page}
            onSearch={handleSearch}
          />
        )}
      </S.FooterContainer>

      <S.Panel>
        {loading && (
          <Loader loading={loading} align="center" message="Carregando..." />
        )}
        {posts?.data.map((item: IPost) => (
          <Card
            key={String(item.id)}
            post={item}
            onOpenModalRemove={handleOpenModalRemover}
          />
        ))}
      </S.Panel>

      <Modal ref={modalRemoverRef} options={{ width: '400px' }}>
        <ModalHeader>
          <ModalTitle>Você Confirma a EXCLUSÃO?</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>O registro será removido completamente!</p>
        </ModalBody>
        <ModalFooter>
          <Position align="right">
            <Button
              variant="outline-secondary"
              onClick={() => modalRemoverRef.current?.closeModal()}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleRemove()}
              style={{ marginLeft: '16px' }}
            >
              SIM, confirmo a exclusão
            </Button>
          </Position>
        </ModalFooter>
      </Modal>
    </S.Container>
  );
};

export default Post;
