/* eslint-disable  no-undef */
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { format, parseISO, formatDistanceToNow } from 'date-fns';

import {
  MdCheckCircle,
  MdDelete,
  MdOpenInBrowser,
  MdBookmark,
} from 'react-icons/md';
import * as S from './styles';
import IPost from '../interfaces/IPost';

// eslint-disable-next-line
import ptBR from 'date-fns/locale/pt-BR';
interface CardProps {
  post: IPost;
  onOpenModalRemove(post: IPost): void;
}

const Card: React.FC<CardProps> = ({ post, onOpenModalRemove }) => {
  const parseCreatedAt = format(
    parseISO(post?.created_at),
    "dd/MM/yyyy HH'h'mm",
    {
      locale: ptBR,
    },
  );
  const parseUpdatedAt = formatDistanceToNow(parseISO(post?.updated_at), {
    locale: ptBR,
    addSuffix: true,
  });

  useCallback(
    (postSelected: IPost) => {
      onOpenModalRemove(postSelected);
    },
    [onOpenModalRemove],
  );

  return (
    <S.Container color={post.channel.bg_color}>
      <S.Post>
        <S.PostContainer>
          <S.ContainerImage>
            <S.Image loading="lazy" src={post.image_crop_url} />
            <S.Channel
              bgColor={post.channel.bg_color}
              lbColor={post.channel.lb_color}
            >
              {post.channel.name}
            </S.Channel>
          </S.ContainerImage>
          <S.Content>
            <S.PostInfo>
              <S.TopBar>
                <S.Hat
                  bgColor={post.channel.bg_color}
                  lbColor={post.channel.lb_color}
                >
                  {post.hat}
                </S.Hat>

                <S.Legend>
                  <S.Position>{post.position.name}</S.Position>
                </S.Legend>
              </S.TopBar>
              <Link to={`/posts/${post.id}`}>
                <S.Title>{post.title}</S.Title>
                <S.SubTitle>{post.subtitle}</S.SubTitle>
              </Link>
            </S.PostInfo>

            <S.PostFooter>
              <S.AuthorContainer>
                <S.Avatar src={post.user.avatar_url} />
                <S.AuthorInfo>
                  <S.AuthorName>{post.user.name}</S.AuthorName>
                  <S.CreatedAt>Criado: {parseCreatedAt}</S.CreatedAt>
                  <S.LastUpdatedAt>
                    Atualizado: {parseUpdatedAt}
                  </S.LastUpdatedAt>
                </S.AuthorInfo>
              </S.AuthorContainer>

              <S.Actions>
                <span
                  title={post.source ? 'Notícia copiada' : 'Notícia do plox'}
                >
                  <MdBookmark
                    size={32}
                    color={
                      post.source
                        ? 'var(--icon-color)'
                        : 'var(--disable-noticia-copiada)'
                    }
                  />
                </span>
                <a
                  title="Visualizar notícia"
                  href={`${process.env.REACT_APP_URL_SITE}/noticia/${post.day}/${post.month}/${post.year}/${post.slug}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <MdOpenInBrowser size={32} color="var(--icon-color)" />
                </a>
                <button
                  type="button"
                  title="Excluir"
                  style={{ cursor: 'pointer' }}
                  onClick={() => onOpenModalRemove(post)}
                >
                  <MdDelete size={32} color="var(--icon-color)" />
                </button>
                <span title={post.publish ? 'Notícia publicada' : 'Rascunho'}>
                  <MdCheckCircle
                    size={32}
                    color={post.publish ? 'var(--enable)' : 'var(--disable)'}
                  />
                </span>
              </S.Actions>
            </S.PostFooter>
          </S.Content>
        </S.PostContainer>
      </S.Post>
    </S.Container>
  );
};

export default Card;
