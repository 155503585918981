import React, { useState, useCallback, useEffect } from 'react';

import * as S from './styles';

interface Search {
  page: number;
  per_page: number;
}

interface PaginationProps {
  page: number;
  per_page: number;
  total: number;
  last_page: number;
  onSearch: ({ page, per_page }: Search) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  per_page,
  total,
  last_page,
  onSearch,
}) => {
  const [perPage, setPerPage] = useState(per_page);
  const [disabledNextPage, setDisabledNextPage] = useState(false);
  const [disabledPreviousPage, setDisabledPreviousPage] = useState(false);

  useEffect(() => {
    setDisabledPreviousPage(page <= 1);
    setDisabledNextPage(page >= last_page);
  }, [page, last_page]);

  const handleNextPage = useCallback(async () => {
    const nextPage = page + 1;
    if (nextPage <= last_page) {
      onSearch({ page: nextPage, per_page: perPage });
    }
  }, [page, last_page, perPage, onSearch]);

  const handlePreviousPage = useCallback(async () => {
    const previousPage = page - 1;
    if (previousPage > 0) {
      onSearch({ page: previousPage, per_page: perPage });
    }
  }, [page, perPage, onSearch]);

  const handleSetPerPage = useCallback(
    (perPageNumber: number) => {
      setPerPage(perPageNumber);
      onSearch({ page: 1, per_page: perPageNumber });
    },
    [onSearch],
  );

  return (
    <S.Container>
      <S.PaginantionInfo>
        <p>
          Página: {page}/{total} itens por página:
        </p>
        <S.SelectPerPage
          onChange={(e) => handleSetPerPage(Number(e.target.value))}
          value={perPage}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
        </S.SelectPerPage>
      </S.PaginantionInfo>

      <S.PaginationButtons>
        <S.IconPreviusPage
          disabled={disabledPreviousPage}
          onClick={handlePreviousPage}
          title="Anterior"
        />
        <S.IconNextPage
          disabled={disabledNextPage}
          onClick={handleNextPage}
          title="Próxima"
        />
      </S.PaginationButtons>
    </S.Container>
  );
};

export default Pagination;
