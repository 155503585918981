import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const ContainerDatePicker = styled(DatePicker)`
  width: 100%;
  background: var(--background-input);
  border-radius: 4px;
  padding: 1.2rem;
  border: 1px solid var(--border-input);
  color: var(--text);
  font-size: 1.6rem;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const ErrorMsg = styled.span`
  margin-left: 8px;
  color: #c53030;
  font-weight: bold;
`;
