import React from 'react';

import * as S from './styles';

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

interface ItemProps {
  id: number;
  name: string;
}

interface Checkprops {
  items: ItemProps[];
  register: RefReturn;
  name: string;
}

const Checkbox: React.FC<Checkprops> = ({ items, register, name }) => {
  return (
    <S.Container>
      {items.map((check: ItemProps) => (
        <label htmlFor={`${name}-${check.id}`} key={check.id}>
          <input
            id={`${name}-${check.id}`}
            type="checkbox"
            ref={register}
            name={name}
            value={check.id}
          />
          <span className="checkmark" />
          {check.name}
        </label>
      ))}
    </S.Container>
  );
};

export default Checkbox;
