import React, { ReactElement } from 'react';
import { MdAssignment } from 'react-icons/md';

import { Container, MainMenu } from './styles';
import { useSiedeBar } from '../../hooks/sidebar';
import ItemMenu from './ItemMenu';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  children: Menu[];
}

const SideBar: React.FC = () => {
  const { isOpened } = useSiedeBar();

  const menus: Menu[] = [
    /* {
      name: 'Dashboard',
      route: '/dashboard',
      icon: <MdDashboard size={24} color="var(--icon-color)" />,
      children: [],
    }, */
    {
      name: 'Noticias',
      route: '/posts',
      icon: <MdAssignment size={24} color="var(--icon-color)" />,
      children: [],
    },
    // {
    //   name: 'Estoque',
    //   route: '#',
    //   icon: <FaBoxOpen size={22} color="var(--icon-color)" />,
    //   children: [
    //     {
    //       name: 'Produtos',
    //       route: '/products',
    //       icon: null,
    //       children: [],
    //     },
    //     {
    //       name: 'Categoria',
    //       route: '/products/categories',
    //       icon: null,
    //       children: [],
    //     },
    //   ],
    // },
    // {
    //   name: 'Financeiro',
    //   route: '/financeiro',
    //   icon: <MdSwapHoriz size={24} color="var(--icon-color)" />,
    //   children: [],
    // },
    // {
    //   name: 'Relatórios',
    //   route: '/reports',
    //   icon: <MdAssignment size={24} color="var(--icon-color)" />,
    //   children: [],
    // },
    // {
    //   name: 'Controle de ACL',
    //   route: '#',
    //   icon: <MdPermDataSetting size={24} color="var(--icon-color)" />,
    //   children: [
    //     {
    //       name: 'Funções/Papeis',
    //       route: '/roles',
    //       icon: null,
    //       children: [],
    //     },
    //     {
    //       name: 'Permissões',
    //       route: '/permissions',
    //       icon: null,
    //       children: [],
    //     },
    //   ],
    // },
    // {
    //   name: 'Cadastros',
    //   route: '#',
    //   icon: <GoSettings size={24} color="var(--icon-color)" />,
    //   children: [
    //     {
    //       name: 'Clientes',
    //       route: '/clientes',
    //       icon: null,
    //       children: [],
    //     },
    //     {
    //       name: 'Funcionários',
    //       route: '/funcionarios',
    //       icon: null,
    //       children: [],
    //     },
    //   ],
    // },
  ];

  return (
    <Container isMenuOpened={isOpened}>
      <MainMenu>
        {menus.map((menu: Menu) => (
          <ItemMenu key={menu.name} menu={menu} />
        ))}
      </MainMenu>
    </Container>
  );
};

export default SideBar;
