import React, { ChangeEvent, useState, useCallback, useRef } from 'react';
// import { useField } from '@unform/core';
import { MdCameraAlt } from 'react-icons/md';

import * as S from './styles';
import './styles';

interface Props {
  name: string;
}

// eslint-disable-next-line no-undef
type InputProps = JSX.IntrinsicElements['input'] & Props;

const Upload: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // const { fieldName, defaultValue, registerField } = useField(name);
  const [preview, setPreview] = useState<null | string>(null);

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      setPreview(null);
    }
    const previewURL = URL.createObjectURL(file);
    setPreview(previewURL || '');
  }, []);
  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: inputRef.current,
  //     path: 'files[0]',
  //     clearValue(ref: HTMLInputElement) {
  //       ref.value = '';
  //       setPreview(null);
  //     },
  //     setValue(_: HTMLInputElement, value: string) {
  //       setPreview(value);
  //     },
  //   });
  // }, [fieldName, registerField]);

  // console.log('Preview: ', preview);

  return (
    <S.Container preview={preview || ''}>
      <input
        name={name}
        id="input"
        type="file"
        ref={inputRef}
        onChange={handlePreview}
        {...rest}
      />
      {!preview && <MdCameraAlt size={24} color="var(--icon-color)" />}
    </S.Container>
  );
};

export default Upload;
