import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FiMapPin } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
// import ptBR, { format, parseISO } from 'date-fns';
import * as Yup from 'yup';
import {
  HeaderInfo,
  FormGroup,
  Label,
  Input,
  Checkbox,
  Button,
  InputTextArea,
} from '../../../components';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import { MunicipioProps } from '../index';

import * as S from './styles';

type FormData = {
  codigo: number;
  slug: string;
  nome: string;
  uf: string;
  description: string;
  ativo: boolean;
};

type RouterParams = {
  codigo?: string | undefined;
};

const schema = Yup.object().shape({
  codigo: Yup.number().required('IBGE é obrigatório'),
  slug: Yup.string().required('IBGE é obrigatório'),
  nome: Yup.string().required('Nome é obrigatório'),
  uf: Yup.string().required('UF é obrigatório'),
  description: Yup.string(),
  ativo: Yup.boolean(),
});

export default function MunicipioForm() {
  const { addToast } = useToast();
  const { codigo } = useParams<RouterParams>();
  const { register, handleSubmit, reset, errors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = React.useState(false);
  const [, setMunicipio] = React.useState<MunicipioProps>();

  async function loadMunicipio(ibge: string) {
    const { data } = await api.get<MunicipioProps>(`/municipios/${ibge}`);
    setMunicipio(data);
    reset(data);
  }

  async function onSubmit(data: FormData) {
    try {
      setLoading(true);
      const values = {
        slug: data.slug,
        nome: data.nome,
        uf: data.uf,
        description: data.description,
        ativo: data.ativo,
      };
      await api.put(`/municipios/${codigo}`, values);
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Salvo com sucesso',
      });
    } catch (err) {
      const errorText = err.response && err.response.data.message;

      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: `Erro: ${errorText}`,
      });
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (codigo) {
      loadMunicipio(codigo);
    }

    // eslint-disable-next-line
  }, [codigo]);

  return (
    <S.Container>
      <S.HeaderPageDatail>
        <HeaderInfo title="Município" icon={FiMapPin} />
      </S.HeaderPageDatail>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>IBGE</Label>
            <Input
              register={register}
              errors={errors.codigo}
              name="codigo"
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label>Slug</Label>
            <Input register={register} errors={errors.slug} name="slug" />
          </FormGroup>
          <FormGroup>
            <Label>Municipio</Label>
            <Input register={register} errors={errors.nome} name="nome" />
          </FormGroup>
          <FormGroup>
            <Label>UF</Label>
            <Input register={register} errors={errors.uf} name="uf" />
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <InputTextArea
              register={register}
              errors={errors.description}
              name="description"
              rows={5}
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              register={register}
              name="ativo"
              items={[{ id: 1, name: 'Ativar ?' }]}
            />
          </FormGroup>

          <FormGroup>
            <Button
              variant="primary"
              disabled={loading}
              loading={loading}
              type="submit"
              title="Salvar"
            >
              Salvar
            </Button>
          </FormGroup>
        </form>
      </S.Panel>
    </S.Container>
  );
}
