import React, { TextareaHTMLAttributes, useState, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error } from './styles';

type RefReturn =
  | string
  | ((instance: HTMLTextAreaElement | null) => void)
  | React.RefObject<HTMLTextAreaElement>
  | null
  | undefined;

interface InputTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
  register: RefReturn;
  errors: FieldError | undefined;
}

const InputTextArea: React.FC<InputTextAreaProps> = ({
  name,
  containerStyle = {},
  icon: Icon,
  register,
  errors,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocused = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return (
    <Container style={containerStyle} isFocused={focused} isErrored={!!errors}>
      {Icon && <Icon size={20} />}
      <textarea
        onFocus={handleFocused}
        onBlur={handleBlur}
        name={name}
        ref={register}
        {...rest}
      />
      {errors && (
        <Error title={errors?.message || ''}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default InputTextArea;
