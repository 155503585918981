import { createGlobalStyle } from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';

export default createGlobalStyle`

  /**
  *  Responsividade
  ## CSS Units  ( Unidades de medidas do CSS)

  ### Layout Fixo
  px - Pixels

  ### Layout Responsivo
  % - Porcentagem
  auto - Automática
  vh - Viewport Height
  vw - Viewport Weidh

  ### Texto Fixos
  1px = 0.75pt
  16px = 12pt

  ### Texto Responsivos
  em = Multiplicado pelo Pai (cuidado pode ocorrencer em cascata)
  rme = Multiplicado pelo Root  (font-size: 62.5%; A cada 1rem será considerado 10px )

  # Media queries
  @media (max-width: 768px) {} será aplicado de 0 <= 768px
  @media (min-width: 768px) {} será aplicado de >= 768px

  */

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    min-height: 100vh;
    /* a cada 1rem será considerado 10px */
    font-size: 62.5%;
  }


  body, input , button {
    font: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.6rem;
    transition: color .2s ease-out;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }



  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  :root {
    --enable: #28a745;
    --disable: #b2bac7;
    --error: #c53030;
    --background-text-error: #fff;
    --primary: #3498db;
    --primary-hover: #0069d9;
    --primary-text: #fff;
    --success: #28a745;
    --danger: #dc3545;

    --icon-color: #707070;

    --button-text: #fff;
    --button-primary: #3498db;
    --button-primary-hover: #0069d9;
    --button-primary-border: #0069d9;
    --button-secondary: #6c757d;
    --button-secondary-hover: #5a6268;
    --button-secondary-border: #545b62;
    --button-success: #28a745;
    --button-success-hover: #218838;
    --button-success-border: #1e7e34;
    --button-warning: #ffc107;
    --button-warning-hover: #e0a800;
    --button-warning-border: #d39e00;
    --button-danger: #dc3545;
    --button-danger-hover: #c82333;
    --button-danger-border: #bd2130;
    --button-info: #17a2b8;
    --button-info-hover: #138496;
    --button-info-border: #117a8b;
    --button-light: #f8f9fa;
    --button-light-hover: #e2e6ea;
    --button-light-border: #dae0e5;
    --button-dark: #343a40;
    --button-dark-hover: #23272b;
    --button-dark-border: #1d2124;

  }

  body.light-theme {

    /** Global */
    --background: #F1F4F9;
    --text: #262626;
    --placeholder: #999;
    --border: #e0e0e0fc;
    --backgroun-icon-hover: rgba(0, 0, 0, 0.1);
    --disable-noticia-copiada: #b2bac7;

    /** AutocompleMulti */
    --background-selected: rgb(230, 230, 230);

    /** PopupMenu */
    --color-popoup-menu-hover: #3498db;

    /** Loader */
    --border-loading-active: #3498db;
    --border-loading: #e6e6e6;

    /** Header */
    --background-header: #3498db;
    --text-header: #fff;

    /** SideBar */

    --background-sidebar: #fff;
    --text-item-menu: #262626;
    --backgrount-item-menu-hover: #faece9;
    --text-item-menu-hover: #3498db;
    --link-active-sidebar: #3498db;
    --link-border-active-sidebar: #3498db;

    /** SubMenu */
    --background-color-submenu: #F1F4F9;

    /** Header Pages */
    --background-header-icon: #fff;
    --background-header-text: #fff;
    --background-header-page-1: #3498db;
    --background-header-page-2: #0069d9;

    /** PANEL */
    --background-panel: #fff;

    /** TABLE */
    --border-table: #e0e0e0fc;

    /** PAGINATION */
    --background-pagination-icon-hover: rgba(0, 0, 0, 0.04);
    --background-pagination-icon-disabled: #efe0e0;
    --background-pagination-icon-enabled: #262626;

    /** Input */
    --background-input: #fff;
    --border-input: #ced4da;
    --selected-input: #3498db;

    /** Link */
    --link: #3498db;
    --link-hover: #0069d9;
  }

  body.dark-theme {

    /** Global */
    --background: #121214;
    --text: #87868b;
    --placeholder: #4c4c50;
    --border: #515151;
    --backgroun-icon-hover: rgba(255, 255, 255, 0.3);
    --disable-noticia-copiada: #323538;

    /** Loader */
    --border-loading-active: #3498db;
    --border-loading: #e6e6e6;


    /** Paleta colors */
    /* --primary: #202024;
    --primary-hover: #29292E;
    --primary-text: #fff; */


    /** Header */
    --background-header: #202024;
    --text-header: #fff;

    /** SideBar */
    --background-sidebar: #26262B;
    --text-item-menu: #707070;
    --backgrount-item-menu-hover: #3f3635c2;
    --text-item-menu-hover: #3498db;
    --link-active-sidebar: #3498db;
    --link-border-active-sidebar: #3498db;


    /** AutocompleMulti */
    --background-selected: #121214;

    /** SubMenu */
    --background-color-submenu: #121214;

    /** Header Pages */
    --background-header-icon: #fff;
    --background-header-text: #fff;
    --background-header-page-1: #3498db;
    --background-header-page-2: #0069d9;

    /** PANEL */
    --background-panel: #26262B;

    /** TABLE */
    --border-table: #515151;

    /** PAGINATION */
    --background-pagination-icon-hover: #26262B;
    --background-pagination-icon-disabled: #515151;
    --background-pagination-icon-enabled: #fff;

    /** Input */
    --background-input: #202024;
    --border-input: #202024;
    --selected-input: #3498db;

     /** Link */
     --link: #3498db;
     --link-hover: #0069d9;
  }

  body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background: var(--background);
    color: var(--text);
    -webkit-font-smoothing: antialiased;
  }

  /** Route active SideBarMenu */
  .sideBarLinkActive  span,
  .sideBarLinkActive  svg {
    color: var(--link-active-sidebar) !important;
    font-weight: 700;
  }

  /** HELPERS */

  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }

  /* for custom scrollbar for webkit browser*/
/*
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
} */

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
    background-color:#fff;
    width:16px
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color:#fff
}
::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:5px solid #fff
}
::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {display:none}



`;
