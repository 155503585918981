import React from 'react';
import { FieldError, Controller } from 'react-hook-form';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { FiAlertCircle } from 'react-icons/fi';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt-BR';
import * as S from './styles';
import './styles.css';

registerLocale('pt', pt);
setDefaultLocale('pt');

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;
interface Props {
  name: string;
  showTime?: boolean;
  register: RefReturn;
  control: any;
  errors: FieldError | undefined;
}

const InputDatePicker: React.FC<Props> = ({
  register,
  control,
  name,
  errors,
  showTime = false,
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        ref={register}
        defaultValue={new Date()}
        render={({ onChange: handleOnChange, value }) => (
          <S.ContainerDatePicker
            selected={value}
            onChange={handleOnChange}
            locale="pt"
            timeFormat="HH:mm"
            timeIntervals={15}
            isClearable
            highlightDates={[new Date()]}
            timeCaption="Hora"
            showTimeSelect={showTime}
            /* timeInputLabel="Hora:"
              showTimeInput={showTime} */
            dateFormat={showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy '}
            todayButton="Hoje"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        )}
      />
      {errors && (
        <S.ErrorContainer>
          <FiAlertCircle color="#c53030" size={20} />
          <S.ErrorMsg>{errors?.message}</S.ErrorMsg>
        </S.ErrorContainer>
      )}
    </>
  );
};

export default InputDatePicker;
