import React from 'react';

import * as S from './styles';

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

interface Option {
  id: string | number;
  checked?: boolean;
  name: string;
  color?: string;
}
interface RadioProps {
  name: string;
  register: RefReturn;
  options: Option[];
  columns?: number;
}

const Radio: React.FC<RadioProps> = ({
  name,
  register,
  options,
  columns = 1,
}) => {
  return (
    <S.Container columns={columns}>
      {options.map((option) => (
        <S.LabelContainer color={option.color} key={String(option.id)}>
          <input
            type="radio"
            name={name}
            id={String(option.id)}
            value={option.id}
            ref={register}
            checked={option.checked}
          />
          <span className="checkmark" />
          {option.name}
        </S.LabelContainer>
      ))}
    </S.Container>
  );
};

export default Radio;
