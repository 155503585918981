import React from 'react';

import { Container } from './styles';

interface ImageProps {
  src?: string;
  alt?: string;
  width?: string;
  height?: string;
  rounded?: boolean;
  roundedCircle?: boolean;
}

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  width = '50',
  height = '50',
  rounded = false,
  roundedCircle = false,
  ...rest
}) => {
  return (
    <Container rounded={rounded} roundedCircle={roundedCircle}>
      <img {...rest} width={width} height={height} src={src} alt={alt} />
    </Container>
  );
};

export default Image;
