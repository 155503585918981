/* eslint-disable import/no-duplicates */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { FiMapPin } from 'react-icons/fi';
import { MdCheckCircle, MdEdit, MdSearch } from 'react-icons/md';
import { HeaderInfo, InputSearch, Loader } from '../../components';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import * as S from './styles';

export type MunicipioProps = {
  codigo: number;
  nome: string;
  uf: string;
  ativado_em: string;
  iniciado_em: string;
  desativado_em: string;
  ativado_em_formatted?: string;
  iniciado_em_formatted?: string;
  desativado_em_formatted?: string;
  slug: string;
  ativo: boolean;
};

export default function Municipio() {
  const { addToast } = useToast();
  const history = useHistory();
  // const [paginate, setPaginate] = React.useState<PaginateProps>();
  const [municipios, setMunicipios] = React.useState<MunicipioProps[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [term, setTerm] = React.useState('');

  async function handleSearch({ pageNumber = 1 }) {
    try {
      setLoading(true);

      const params = {
        page: pageNumber,
        per_page: 12,
        term,
      };
      const response = await api.get('/municipios', { params });
      const { data } = response;

      const formatDateTemplate = "dd/MM/yyyy HH'h'mm";
      const municipiosFormatted = data?.data?.map(
        (municipio: MunicipioProps) => ({
          ...municipio,
          iniciado_em_formatted: municipio.iniciado_em
            ? format(parseISO(municipio.iniciado_em), formatDateTemplate, {
                locale: ptBR,
              })
            : '',
          ativado_em_formatted: municipio.ativado_em
            ? format(parseISO(municipio.ativado_em), formatDateTemplate, {
                locale: ptBR,
              })
            : '',
          desativado_em_formatted: municipio.desativado_em
            ? format(parseISO(municipio.desativado_em), formatDateTemplate, {
                locale: ptBR,
              })
            : '',
        }),
      );

      setMunicipios(municipiosFormatted);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops...',
        description: 'Ops, ocorreu um erro no processo de pesquisa',
      });
    } finally {
      setLoading(false);
    }
  }

  function onSearch(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handleSearch({
        pageNumber: 1,
      });
    }
  }

  function handleEdit(codigo: number) {
    history.push(`/municipios/${codigo}`);
  }

  React.useEffect(() => {
    handleSearch({ pageNumber: 1 });
    // eslint-disable-next-line
  }, []);

  return (
    <S.Container>
      <S.HeaderPage>
        <HeaderInfo title="Municípios" icon={FiMapPin} />

        <S.ContainerSearch>
          <InputSearch
            title="Pesquisar pelo nome do município"
            icon={MdSearch}
            placeholder="Pesquisar por município"
            onChange={(e) => setTerm(e.target.value)}
            onKeyDown={onSearch}
          />
        </S.ContainerSearch>

        {/* <div>
          <Button title="Adicionar" onClick={handleAdd}>
            Adicionar
          </Button>
        </div> */}
      </S.HeaderPage>

      <S.Panel>
        {loading && (
          <Loader loading={loading} align="center" message="Carregando..." />
        )}
        {municipios?.map((municipio: MunicipioProps) => (
          <S.ContainerMuncipio key={municipio.codigo}>
            <S.MunicipioInfo>
              <S.MunicipioHeader>
                <S.Name>
                  {municipio.nome} - {municipio.uf}
                </S.Name>
                <span>
                  <MdCheckCircle
                    size={32}
                    color={municipio.ativo ? 'var(--enable)' : 'var(--disable)'}
                  />
                </span>
              </S.MunicipioHeader>

              <S.Slug>
                Slug: <strong>{municipio.slug || '----'}</strong>
              </S.Slug>
              <S.Ibge>
                IBGE: <strong>{municipio.codigo}</strong>
              </S.Ibge>
            </S.MunicipioInfo>
            <S.Actions>
              <S.StartAt>
                Ativado em:{' '}
                <strong>{municipio.ativado_em_formatted || '----'}</strong>
              </S.StartAt>

              <button
                type="button"
                title="Editar"
                style={{ cursor: 'pointer' }}
                onClick={() => handleEdit(municipio.codigo)}
              >
                <MdEdit size={24} color="var(--icon-color)" />
              </button>
            </S.Actions>
          </S.ContainerMuncipio>
        ))}
      </S.Panel>
    </S.Container>
  );
}
