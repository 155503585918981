import styled from 'styled-components';

interface ContainerProps {
  columns: number;
}

export const Container = styled.ul<ContainerProps>`
  margin-top: 8px;
  margin-bottom: 32px;
  columns: ${({ columns }) => columns};
  list-style: none;
  padding: 0px !important;
`;

interface LabelContainerProps {
  color?: string;
}

export const LabelContainer = styled.label<LabelContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 700;
  user-select: none;
  color: ${({ color }) => (color !== undefined ? color : 'var(--text)')};
  input {
    position: absolute;
    opacity: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: ${({ color }) =>
      color !== undefined ? color : 'var(--primary)'};
  }
  &:hover input ~ .checkmark {
    background: ${({ color }) =>
      color !== undefined ? color : 'var(--primary)'};
  }
  input:checked ~ .checkmark {
    background: ${({ color }) =>
      color !== undefined ? color : 'var(--primary)'};
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 5px;
    top: 5px;
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
  }
`;
