import styled from 'styled-components';

export const Container = styled.div`
  .ck-editor__editable_inline {
    min-height: 700px;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const ErrorMsg = styled.span`
  margin-left: 8px;
  color: #c53030;
  font-weight: bold;
`;
