import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputFile = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;

  & + label {
    background-color: var(--primary);
    padding: 8px 16px;
    border-radius: 4rem;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding-left: 2rem 4rem;
  }

  &:focus + label,
  & + label:hover {
    background-color: var(--primary-hover);
  }

  &:focus + label {
    outline: 1px dotted #000;
  }
`;
