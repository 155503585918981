export default [
  {
    id: 1,
    name: 'Manchete',
  },
  {
    id: 2,
    name: 'Sub-Manchete',
  },
  {
    id: 3,
    name: 'Slide',
  },
  {
    id: 4,
    name: 'Destaque',
  },
  {
    id: 5,
    name: 'Reservado',
  },
];
