import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { parseISO } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import { MdAssignment } from 'react-icons/md';

import {
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
  Radio,
  InputEditor,
  InputDatePicker,
  Checkbox,
  AutoComplete,
} from '../../../components';

import InputImageCrop, { CropProps } from '../../../components/InputImageCrop';
import * as S from './styles';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import channels from './channels';
import positions from './positions';

type Location = {
  id: number;
  name: string;
};
type FormData = {
  id: number;
  published_at: Date;
  published_by: string;
  publish: boolean;
  hat: string;
  title: string;
  teaser_local: string;
  teaser_state: string;
  teaser_nation: string;
  subtitle: string;
  source: string | null;
  body: string;
  channel_id: string;
  position_id: string;
  locations: string;
  image_alt: string | null;
  image_original_url: string;
  image_crop_url: string;
  image_original?: string;
  image_crop?: string;
  image_original_file?: File;
  image_crop_file?: File;
  aspect: number;
  height: number;
  unit: string;
  width: number;
  x: number;
  y: number;
};

type Post = {
  id: number;
  published_at: string;
  published_by: string;
  publish: boolean;
  title: string;
  teaser_local: string;
  teaser_state: string;
  teaser_nation: string;
  subtitle: string;
  hat: string;
  source: string | null;
  channel_id: string;
  position_id: string;
  locations: Location[];
  body: string;
  image_alt: string | null;
  image_original?: string;
  image_crop?: string;
  image_original_url: string;
  image_crop_url: string;
  unit: string;
  x: number;
  y: number;
  width: number;
  height: number;
  aspect: number;
};

type RouterParams = {
  id?: string | undefined;
};

type Files = {
  image_original_url?: string;
  image_crop_url?: string;
  image_original_file?: File;
  image_crop_file?: File | Blob;
  name?: string;
  crop: CropProps;
  isfileChanged: boolean;
};

const schema = Yup.object().shape({
  published_at: Yup.date().required('Data da publicação é obrigatória'),
  published_by: Yup.string().required('Publicado por é obrigatório'),
  publish: Yup.boolean(),
  title: Yup.string().required('Título é obrigatório'),
  subtitle: Yup.string().required('Subtítulo é obrigatório'),
  hat: Yup.string().required('Bigode é obrigatório'),
  source: Yup.string(),
  body: Yup.string().required('A Notícia é obrigatória'),
  channel_id: Yup.string().required('Canal é obrigatório'),
  position_id: Yup.string().required('Posição é obrigatório'),
  locations: Yup.string().required('É obrigatório informar a localidade'),
});

const PostForm: React.FC = () => {
  const { id } = useParams<RouterParams>();
  const { register, handleSubmit, reset, errors, control, watch, setValue } =
    useForm<FormData>({
      resolver: yupResolver(schema),
    });
  const history = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [files, setFiles] = useState<Files>();

  const inputTitle = watch('title');

  useEffect(() => {
    setValue('teaser_local', inputTitle);
    setValue('teaser_state', inputTitle);
    setValue('teaser_nation', inputTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTitle]);

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        const values = {
          ...data,
          ...files,
        };

        const formData = new FormData();
        formData.append('published_at', String(values.published_at));
        formData.append('published_by', values.published_by);
        formData.append('publish', String(values.publish));
        formData.append('title', values.title);
        formData.append('teaser_local', values.teaser_local);
        formData.append('teaser_state', values.teaser_state);
        formData.append('teaser_nation', values.teaser_nation);
        formData.append('subtitle', values.subtitle);
        formData.append('hat', values.hat);
        formData.append('source', values.source || '');
        formData.append('locations', values.locations);
        formData.append('channel_id', String(values.channel_id));
        formData.append('position_id', String(values.position_id));
        formData.append('body', values.body);
        if (files?.image_crop_file) {
          formData.append(
            'image_original',
            files?.image_original_file as File,
            files?.name,
          );
          formData.append(
            'image_crop',
            files?.image_crop_file as File,
            files?.name,
          );
        }
        formData.append('x', String(files?.crop.x));
        formData.append('y', String(files?.crop.y));
        formData.append('width', String(files?.crop.width));
        formData.append('height', String(files?.crop.height));
        formData.append('aspect', String(files?.crop.aspect));
        formData.append('isfileChanged', String(files?.isfileChanged));

        if (id) {
          await api.put<Post>(`/posts/${id}`, formData);
        } else {
          await api.post('/posts', formData);
          history.push('/posts');
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        let errorText = err.response && err.response.data.message;

        errorText = String(errorText).includes('Invalid file type')
          ? 'Formato imagem inválido, permitido [JPEG / JPG]'
          : errorText;

        const description = errorText || err;

        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: `Erro: ${description}`,
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [addToast, id, files],
  );

  const handleFiles = useCallback(
    (
      image_original_file: File,
      image_crop_file: File | Blob,
      name: string,
      crop: CropProps,
    ) => {
      setFiles({
        ...files,
        image_original_file,
        image_crop_file,
        name,
        crop,
        isfileChanged: true,
      });
    },
    [files],
  );

  useEffect(() => {
    const newPost = {
      id: 0,
      published_at: new Date(),
      published_by: 'Plox',
      publish: true,
      hat: '',
      title: '',
      teaser_local: '',
      teaser_state: '',
      teaser_nation: '',
      subtitle: '',
      source: '',
      body: '',
      channel_id: '1',
      position_id: '4',
      locations: '',
      image_alt: '',
      image_original_url: '',
      image_crop_url: '',
      aspect: 16 / 9,
      height: 309,
      unit: 'px',
      width: 500,
      x: 79.5,
      y: 38.171875,
    };

    reset(newPost);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Number(id) > 0) {
      api.get<Post>(`/posts/${id}`).then((response) => {
        const { data } = response;

        setLocations(
          data.locations.map((item: Location) => ({
            id: item.id,
            name: item.name,
          })),
        );
        const dataFormatted = {
          ...data,
          position_id: String(data.position_id),
          channel_id: String(data.channel_id),
          published_at: parseISO(data.published_at),
          locations: data.locations.map((item: Location) => item.id).join(','),
        };

        const crop: CropProps = {
          unit: 'px',
          aspect: data?.aspect || 16 / 9,
          height: data?.height || 302,
          width: data?.width || 538,
          x: data?.x || 79.5,
          y: data?.y || 38.171875,
        };

        setFiles({
          image_original_url: data.image_original_url,
          image_crop_url: data.image_crop_url,
          image_original_file: undefined,
          image_crop_file: undefined,
          name: data.image_original,
          crop,
          isfileChanged: false,
        });

        reset(dataFormatted);
      });
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <S.Container>
      <S.HeaderPageDatail>
        <HeaderInfo
          /* title={id ? `Editando: ( ${post?.id} ) - ${post?.title}` : 'Nova Notícia'} */
          title="Notícia"
          icon={MdAssignment}
        />
      </S.HeaderPageDatail>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Checkbox
              register={register}
              name="publish"
              items={[{ id: 1, name: 'Publicar ?' }]}
            />
          </FormGroup>
          <FormGroup>
            <Label>Data Publicação</Label>
            <br />
            <InputDatePicker
              control={control}
              register={register}
              errors={errors.published_by}
              name="published_at"
              showTime
            />
          </FormGroup>
          <FormGroup>
            <Label>Publicado Por</Label>
            <Input
              register={register}
              errors={errors.published_by}
              name="published_by"
              placeholder="Plox"
            />
          </FormGroup>
          <FormGroup>
            <Label>Bigode</Label>
            <Input
              register={register}
              errors={errors.hat}
              name="hat"
              id="inputaHat"
            />
          </FormGroup>
          <FormGroup>
            <Label>Título</Label>
            <Input register={register} errors={errors.title} name="title" />
          </FormGroup>
          <FormGroup>
            <Label>Chamada Local</Label>
            <Input
              register={register}
              errors={errors.teaser_local}
              name="teaser_local"
            />
          </FormGroup>
          <FormGroup>
            <Label>Chamada Estadual</Label>
            <Input
              register={register}
              errors={errors.teaser_state}
              name="teaser_state"
            />
          </FormGroup>
          <FormGroup>
            <Label>Chamada Nacional</Label>
            <Input
              register={register}
              errors={errors.teaser_nation}
              name="teaser_nation"
            />
          </FormGroup>
          <FormGroup>
            <Label>SubTítulo</Label>
            <Input
              register={register}
              errors={errors.subtitle}
              name="subtitle"
            />
          </FormGroup>
          <FormGroup>
            <Label>Fonte</Label>
            <Input register={register} errors={errors.source} name="source" />
          </FormGroup>

          <FormGroup>
            <Label>Localidade</Label>
            <AutoComplete
              control={control}
              register={register}
              errors={errors.locations}
              defaultOption={locations}
              name="locations"
              url="/locations"
            />
          </FormGroup>
          <FormGroup>
            <Label>Canal</Label>
            <Radio
              register={register}
              name="channel_id"
              options={channels}
              columns={5}
            />
          </FormGroup>
          <FormGroup>
            <Label>Posição</Label>
            <Radio
              register={register}
              name="position_id"
              options={positions}
              columns={5}
            />
          </FormGroup>
          <FormGroup>
            <InputEditor
              control={control}
              register={register}
              errors={errors.body}
              name="body"
            />
          </FormGroup>

          <FormGroup>
            <InputImageCrop
              name="file_upload"
              source={files?.image_original_url}
              initCrop={files?.crop}
              sourceCrop={files?.image_crop_url}
              onChange={handleFiles}
              fileNameOriginal={files?.name}
              label="IMAGEM CAPA"
            />
          </FormGroup>

          {/* <FormGroup>
            <Label>Categoria</Label>
            <AutoComplete
              name="category_id"
              placeholder="Categoria"
              url="/posts/categories"
              defaultOption={post?.category}
            />
          </FormGroup> */}
          {/* <FormGroup>
            <Label>Categoria2</Label>
            <Select
              name="category_id2"
              placeholder="Categoria"
              options={[
                { id: undefined, name: undefined },
                { id: '1', name: 'Categoria 1' },
                { id: '2', name: 'Categoria 2' },
                { id: '3', name: 'Categoria 3' },
              ]}
            />
          </FormGroup> */}

          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default PostForm;
