import React, { useRef, useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
// import { FiAlertCircle } from 'react-icons/fi';
// import { useField } from '@unform/core';
import ReactInputMask, { Props } from 'react-input-mask';
// import { Container, Error } from './styles';
import { Container } from './styles';

interface InputProps extends Props {
  name: string;
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
}

const InputMask: React.FC<InputProps> = ({
  name,
  containerStyle = {},
  icon: Icon,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  // const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef?.current);
  }, []);

  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: inputRef.current,
  //     path: 'value',
  //   });
  // }, [fieldName, registerField, inputRef]);

  return (
    <Container
      style={containerStyle}
      isErrored={!!false}
      isFocused={isFocused}
      isFilled={isFilled}
    >
      {Icon && <Icon size={20} />}
      <ReactInputMask
        name={name}
        alwaysShowMask
        onFocus={() => setIsFocused(true)}
        onBlur={handleInputBlur}
        ref={inputRef}
        defaultValue=""
        {...rest}
      />
      {/* {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )} */}
    </Container>
  );
};

export default InputMask;
