import React, {
  SelectHTMLAttributes,
  //  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
// import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';
// import { FiAlertCircle } from 'react-icons/fi';

// import { Container, Error } from './styles';
import { Container } from './styles';

interface Option {
  id: string | undefined;
  name?: string;
}

interface InputProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  containerStyle?: object;
  options: Option[];
  icon?: React.ComponentType<IconBaseProps>;
}

const Select: React.FC<InputProps> = ({
  name,
  options,
  containerStyle = {},
  icon: Icon,
  ...rest
}) => {
  const inputRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  // const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: inputRef.current,
  //     path: 'value',
  //   });
  // }, [registerField, fieldName, inputRef]);

  return (
    <Container
      style={containerStyle}
      isErrored={!!false}
      isFocused={isFocused}
      isFilled={isFilled}
    >
      {Icon && <Icon size={20} />}
      <select
        name={name}
        onFocus={() => setIsFocused(true)}
        onBlur={handleInputBlur}
        ref={inputRef}
        defaultValue=""
        {...rest}
      >
        {options.map((item) => (
          <option key={String(item.id)} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      {/* {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )} */}
    </Container>
  );
};

export default Select;
